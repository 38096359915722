<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in socials">
              <a
                :key="i"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                target="_blank"
                :title="s.title"
              >
                <v-icon>{{ s.icon  }}</v-icon>
              </a>
              <v-responsive
                v-if="i < socials.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
          v-html="'Tout droit réservé &copy; 2020-'+(new Date().getFullYear())+' Ironwood, mise en place par Stald\'R design'"
          />

      </v-row>

      <v-row>
        <v-col
          class="text-center gcaptcha-banner"
          cols="12"
          style="padding: 0"
        >
          <br> Ce site est protégé par reCAPTCHA et Google
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
          >Politique de confidentialité</a> et
          <a
            href="https://policies.google.com/terms"
            target="_blank"
          >Conditions d'utilisation</a>.
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      socials: [
        {
          title: 'Page Facebook',
          link: 'https://www.facebook.com/ironwoodgt',
          icon: 'mdi-facebook',
        },
        {
          title: 'Page Instagram',
          link: 'https://www.instagram.com/ironwood_grandjean',
          icon: 'mdi-instagram',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
  .gcaptcha-banner
    font-size: 12px
</style>
